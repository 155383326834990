<template>
  <div class="moneylogView">
    <van-sticky>
      <van-nav-bar :title="$t('审核任务')" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-tabs v-model="active" @change="onChange" line-width="60px" background="#151d31" title-inactive-color="#ffffff" title-active-color="#4087f1">
      <van-tab :title="$t('进行中')"></van-tab>
      <van-tab :title="$t('审核中')"></van-tab>
      <van-tab :title="$t('已完成')"></van-tab>
      <van-tab :title="$t('已失败')"></van-tab>
    </van-tabs>
    <van-empty :description="$t('没有数据')" />
  </div>
</template>

<script>
import { getTaskType } from '@/api/utils';
export default {
  data() {
    return {
      typeList: [],
      active: 0,
    };
  },
  created() {
    this.GetTaskType();
  },
  watch: {},
  methods: {
    //查看任务
    onShow(id) {
      this.$router.push({ name: 'TaskInfo', params: { id: id } });
    },
    onChange(value) {
      this.list = [];
      this.page = 1;
      this.finished = false;
    },
    onClickLeft() {
      this.$router.push('/main/user');
    },
    //获取任务分类
    GetTaskType() {
      getTaskType({})
        .then((response) => {
          this.typeList = response.result;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="stylus">
.moneylogView
  .van-tabs__line
    background-color: #4087f1;
  .van-tabs__wrap--scrollable .van-tab
    flex: 0 0 16.67% !important;
    padding: 0 0.23rem;
  .van-list
    margin-top: 0.4rem
    .xiax_item
       background-color: #151d31;
       margin-bottom: 0.15rem;
       line-height: 0.6rem;
       padding: 0.15rem 0.4rem;
       span
         display block
      .van-cell__value
        display: flex
        color: #c0c4cc;
        align-items: center;
        .icon
            color: #fff;
            width: 0.75rem;
            height: 0.75rem;
            line-height: 0.75rem;
            text-align: center;
            border-radius: 100%;
            font-size: .325rem;
            margin-right: .15rem;
            flex: none;
            overflow: hidden;
         .tag0
            background-color: #dd6161;
         .tag1
           background-color:#07c160
         .tag2
           background-color: #1989fa;
        .left
          font-size: 0.32rem
          padding: 6px
          .desc
            font-weight: 500
          .money
            color: $specColor
            font-weight bold
            font-size: 0.42rem
        .right
          flex: 1
          font-size: 0.34rem
          padding: 6px
          text-align: right
          .time
            font-weight: 450
</style>
